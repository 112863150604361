import React from 'react';
import Layout from '../components/Layout';
import { StaticQuery, graphql } from 'gatsby';
import NotFoundTemplate from '../components/NotFoundTemplate';

const NotFoundPage = ({ location: { href } }) => (
  <StaticQuery
    query={graphql`
      query {
        image: contentfulAsset(
          id: { eq: "311d6a7f-6e9c-5f14-af5b-cb0a06aa1cc8" }
        ) {
          title
          fluid(maxWidth: 1000) {
            ...GatsbyContentfulFluid
          }
        }
      }
    `}
    render={data => (
      <Layout canonicalUrl={href}>
        <NotFoundTemplate image={data.image} />
      </Layout>
    )}
  />
);

export default NotFoundPage;
