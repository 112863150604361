import React from 'react';
import styled from 'styled-components';
import Section from '../../elements/Section';
import Title from '../../elements/Title';
import Img from 'gatsby-image';
import Wrapper from '../../elements/Wrapper';
import { media } from '../../utils/styles';

const StyledTitle = styled(Title)`
  color: ${props => props.theme.primary};
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  margin-top: 40px;
`;

const SubTitle = styled.p`
  font-size: 22px;
  text-align: center;
  font-weight: 300;
  color: #4F4F51;
  font-family: ${props => props.theme.sansSerifFontFamily };
`;

const StyledImage = styled(Img)`
  margin-bottom: 30px;
  margin-top: 40px;
  ${media.tablet`
    margin-top: -30px;
    margin-bottom: 50px;
  `};
`;

export default ({ image }) =>
  <Section>
    <Wrapper header={true}>
      <StyledTitle>Whoops!</StyledTitle>
      <SubTitle>We couldn't find the page you were looking for.</SubTitle>
      <StyledImage alt={image.title} fluid={image.fluid} />
    </Wrapper>
  </Section>;